import {sum} from "./library"


async function publishData(){
    //e.preventDefault();
    console.log("run")
    const form = document.querySelector('form')!;
    

    const formData = new FormData(form);
    
    const formJson = Object.fromEntries(formData)

    console.log(formJson)

    const response = await fetch("save_data", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formJson)
    });

    show();
   
}

async function show(){
    

    let response = await fetch("/get_data");
    document.getElementById("result")!.innerHTML = await response.text();
}


(window as any).sum = sum;
(window as any).publishData = publishData;
(window as any).show = show;